import React, {FC, FormEvent, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import BTooltip from "../../../../components/b-tooltip";
import {
    formatNumber,
    formatUnit,
    getPlural,
    getTimeFromNow,
    getTimeLang,
    serializeQuery,
    toGwei,
    toXDCNumber,
    toXDCNumberWithoutFormat,
    truncate,
} from "../../../../common/utils/function";
import AddressName from "../../../common/address-name";
import useCommon from "../../../../common/hooks/useCommon";
import AdvancedFilterChart from "../../../resources/advanced-filter/advanced-filter-chart";
import TxStatus from "../../../common/tx-status";
import BPrice from "../../../common/b-price";
import MethodName from "../../../common/method-name";
import BTable2 from "../../../../components/b-table2";
import {useSelector} from "react-redux";
import {tokenService} from "../../../../store/actions";
import TokenImage from "../../../common/token-image";
import BCopy from "../../../../components/b-copy";

interface ColumnSelectedOptions {
    transactionHash: boolean;
    type: boolean;
    method: boolean;
    blockNumber: boolean;
    age: boolean;
    from: boolean;
    andOr: boolean;
    to: boolean;
    amount: boolean;
    value: boolean;
    asset: boolean;
    txnFee: boolean;
}

interface AdvancedFilterData {
    hash: string;
    type: string;
    method: any;
    block: any;
    timestamp: string | number;
    from: any;
    from_name_tag: any;
    to: any;
    to_name_tag: any;
    status: string;
    amount: number;
    value: string | number;
    name: string;
    symbol: string;
    txnfee: any;
}

interface XRC20TransferListData {
    tx_hash: string;
    timestamp: string | number;
    address: string;
    from: string;
    to: string;
    value: string | number;
    coingeckoid: string | number;
}

const AdvancedFilter: FC = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const [columnSelectedOptions, setColumnSelectedOptions] =
        useState<ColumnSelectedOptions>({
            transactionHash: true,
            type: true,
            method: true,
            blockNumber: true,
            age: true,
            from: true,
            andOr: true,
            to: true,
            amount: true,
            value: true,
            asset: true,
            txnFee: true,
        });
    const [contractAddress, setContractAddress] = useState("");
    const [topic0, setTopic0] = useState("");
    const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

    const [advancedFilter, setAdvancedFilter] = useState<AdvancedFilterData[]>([
        {
            hash: "0x8207f3e5...1cc1bfbba",
            status: "success",
            type: "ERC-20",
            method: "Transfer",
            block: "19923242",
            timestamp: 60,
            from: "0x1f9090aa...8e676c326",
            from_name_tag: "Null: 0x000...000",
            to: "0x8207f3e5...1cc1bfbba",
            to_name_tag: "Uniswap: Universal Router",
            amount: 100,
            value: 100,
            name: "Tether USD",
            symbol: "USDT",
            txnfee: 0.039482,
        },
        {
            hash: "0x8207f3e5...1cc1bfbba",
            status: "success",
            type: "ERC-20",
            method: "Transfer",
            block: "19923242",
            timestamp: 6,
            from: "0x1f9090aa...8e676c326",
            from_name_tag: "Null: 0x000...000",
            to: "0x8207f3e5...1cc1bfbba",
            to_name_tag: "Uniswap: Universal Router",
            amount: 100,
            value: 100,
            name: "Tether USD",
            symbol: "USDT",
            txnfee: 0.039482,
        },
        {
            hash: "0xabc123...def456",
            status: "success",
            type: "ERC-20",
            method: "Transfer",
            block: "19923242",
            timestamp: 2,
            from: "0x123456...789abc",
            from_name_tag: "Null: 0x000...000",
            to: "0xabc123...def456",
            to_name_tag: "",
            amount: 50,
            value: 50,
            name: "Ethereum",
            symbol: "ETH",
            txnfee: 0.039482,
        },
        {
            hash: "0x8207f3e5...1cc1bfbba",
            status: "success",
            type: "ERC-20",
            method: "Transfer",
            block: "19923242",
            timestamp: 60,
            from: "0x1f9090aa...8e676c326",
            from_name_tag: "Null: 0x000...000",
            to: "0x8207f3e5...1cc1bfbba",
            to_name_tag: "Uniswap: Universal Router",
            amount: 100,
            value: 100,
            name: "Tether USD",
            symbol: "USDT",
            txnfee: 0.039482,
        },
        {
            hash: "0x4567ab8e...9df1a2cd",
            status: "success",
            type: "ERC-20",
            method: "Transfer",
            block: "19923345",
            timestamp: 45,
            from: "0x7e9090aa...2c6c4f56",
            from_name_tag: "User: 0x7e9...0aa",
            to: "0x1c8d2e4f...7e3a8cb2",
            to_name_tag: "Uniswap: Universal Router",
            amount: 150,
            value: 150,
            name: "USD Coin",
            symbol: "USDC",
            txnfee: 0.020582,
        },
        {
            hash: "0xa1b2c3d4...e5f6g7h8",
            status: "success",
            type: "ERC-721",
            method: "Mint",
            block: "19923242",
            timestamp: 10,
            from: "0xabcdef...123456",
            from_name_tag: "",
            to: "0xa1b2c3d4...e5f6g7h8",
            to_name_tag: "Opensea: Storefront",
            amount: 1,
            value: 1,
            name: "CryptoKitties",
            symbol: "CK",
            txnfee: 0.012345,
        },
        {
            hash: "0x123abc...456def",
            status: "failed",
            type: "ERC-20",
            method: "Approval",
            block: "19923243",
            timestamp: 12,
            from: "0x654321...fedcba",
            from_name_tag: "Null: 0x000...000",
            to: "0x123abc...456def",
            to_name_tag: "",
            amount: 200,
            value: 200,
            name: "Dai Stablecoin",
            symbol: "DAI",
            txnfee: 0.029382,
        },
        {
            hash: "0x789def...123abc",
            status: "success",
            type: "ERC-20",
            method: "Transfer",
            block: "19923244",
            timestamp: 15,
            from: "0xabcdef...123456",
            from_name_tag: "Null: 0x000...000",
            to: "0x789def...123abc",
            to_name_tag: "Binance: Wallet",
            amount: 500,
            value: 500,
            name: "USD Coin",
            symbol: "USDC",
            txnfee: 0.049384,
        },
        {
            hash: "0x456def...789abc",
            status: "pending",
            type: "ERC-20",
            method: "Transfer",
            block: "19923245",
            timestamp: 20,
            from: "0x654321...fedcba",
            from_name_tag: "Null: 0x000...000",
            to: "0x456def...789abc",
            to_name_tag: "Coinbase: Wallet",
            amount: 1000,
            value: 1000,
            name: "Wrapped Bitcoin",
            symbol: "WBTC",
            txnfee: 0.059285,
        },
        {
            hash: "0xabcdef...123456",
            status: "success",
            type: "ERC-20",
            method: "Swap",
            block: "19923246",
            timestamp: 25,
            from: "0x123456...789abc",
            from_name_tag: "Null: 0x000...000",
            to: "0xabcdef...123456",
            to_name_tag: "Uniswap: Router",
            amount: 300,
            value: 300,
            name: "Chainlink",
            symbol: "LINK",
            txnfee: 0.069286,
        },
        {
            hash: "0x789abc...def123",
            status: "failed",
            type: "ERC-20",
            method: "Transfer",
            block: "19923247",
            timestamp: 30,
            from: "0xabcdef...123456",
            from_name_tag: "Null: 0x000...000",
            to: "0x789abc...def123",
            to_name_tag: "",
            amount: 400,
            value: 400,
            name: "Polygon",
            symbol: "MATIC",
            txnfee: 0.079287,
        },
        {
            hash: "0xdef123...789abc",
            status: "success",
            type: "ERC-20",
            method: "Stake",
            block: "19923248",
            timestamp: 35,
            from: "0xabcdef...123456",
            from_name_tag: "Null: 0x000...000",
            to: "0xdef123...789abc",
            to_name_tag: "Staking: Contract",
            amount: 800,
            value: 800,
            name: "Aave",
            symbol: "AAVE",
            txnfee: 0.089288,
        },
        {
            hash: "0x456abc...def789",
            status: "pending",
            type: "ERC-20",
            method: "Transfer",
            block: "19923249",
            timestamp: 40,
            from: "0x654321...fedcba",
            from_name_tag: "Null: 0x000...000",
            to: "0x456abc...def789",
            to_name_tag: "KuCoin: Wallet",
            amount: 150,
            value: 150,
            name: "SushiSwap",
            symbol: "SUSHI",
            txnfee: 0.099289,
        },
    ]);

    const [totalRecords, setTotalRecords] = useState<number>(
        advancedFilter.length
    );
    const [pagination, setPagination] = useState<any>({
        limit: 50,
        page: 1,
    });
    const [displayChart, setDisplayChart] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const _tokenType = useSelector((e: Object) => (e as any)?.common?.tokenType);
    const {getTime, getXDC, get0x} = useCommon();
    const [transactionList, setTransactionList] = useState<
        XRC20TransferListData[]
    >([]);

    const getTransactions = async () => {
        try {
            setLoading(true);
            tokenService
                .getTokenTransfersList(
                    serializeQuery({token_type: "ERC-20", ...pagination})
                )
                .then((response) => {
                    setTransactionList(response?.items);
                    setTotalRecords(response?.count);
                    setLoading(false);
                })
                .catch((e) => "");
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getTransactions();
    }, [pagination]);

    const handleCheckboxChange = () => {
        setDisplayChart(!displayChart);
    };

    const handle = {
        pagination: (payload: any) => {
            setPagination(payload);
        },
    };

    //   const columnHeaders = {
    //     transactionHash: {
    //       title: `${t("Tx Hash")}`,
    //       param: "hash",
    //       tooltip: (data: any) => (
    //         <>
    //           {data?.status === 1 ? (
    //             <Link to={`/tx/${get0x(data?.hash)}`}>{get0x(data?.hash)}</Link>
    //           ) : null}
    //         </>
    //       ),
    //       render: (data: any) => (
    //         <>
    //           {data?.status === 0 ? (
    //             <BTooltip text={t("Error in Main Txn : execution reverted")}>
    //               <strong>
    //                 <i
    //                   className="bi bi-exclamation-circle text-danger bold"
    //                   style={{ fontWeight: "bold" }}
    //                 ></i>
    //               </strong>
    //             </BTooltip>
    //           ) : null}{" "}
    //           <Link to={`/tx/${get0x(data?.hash)}`}>
    //             {centerTruncate(get0x(data?.hash))}
    //           </Link>
    //         </>
    //       ),
    //     },
    //     type: {
    //       title: `${t("Type")}`,
    //       param: "type",
    //       render: (data: any) => (
    //         <span
    //           style={{ maxWidth: "95px" }}
    //           className="d-block badge border border-opacity-10 text-dark fw-normal text-truncate w-100 py-1.5 bg-light"
    //         >
    //           {data?.type}
    //         </span>
    //       ),
    //     },
    //     method: {
    //       title: `${t("Method")}`,
    //       param: "method",
    //       render: (data: any) => (
    //         <span
    //           style={{ maxWidth: "95px" }}
    //           className="d-block badge border border-opacity-10 text-dark fw-normal text-truncate w-100 py-1.5 bg-light"
    //         >
    //           {data?.method}
    //         </span>
    //       ),
    //     },
    //     blockNumber: {
    //       title: `${t("Block")}`,
    //       param: "block",
    //       render: (data: any) => <span>{data?.block}</span>,
    //     },
    //     age: {
    //       title: `${t("Age")}`,
    //       param: "timestamp",
    //       tooltip: true,
    //       render: (data: any) => <>{data?.timestamp} days ago</>,
    //     },
    //     from: {
    //       title: `${t("From")}`,
    //       param: "from",
    //       copy: (data: any) => getXDC(data?.from),
    //       tooltip: (data: any) => (
    //         <Link to={`/address/${get0x(data?.from)}`}>{getXDC(data?.from)}</Link>
    //       ),
    //       render: (data: any) => (
    //         <Link to={`/address/${get0x(data?.from)}`}>{data?.from}</Link>
    //       ),
    //     },
    //     andOr: {
    //       title: `${t("OR")}`,
    //       param: "or",
    //       render: (data: any) => (
    //         <span
    //           className="bg-success bg-opacity-10 border border-success border-opacity-25 text-success fs-70x content-center rounded-pill mx-auto"
    //           style={{ width: "1.5rem", height: "1.5rem" }}
    //         >
    //           <i className="bi bi-arrow-right"></i>
    //         </span>
    //       ),
    //     },
    //     to: {
    //       title: `${t("To")}`,
    //       param: "to",
    //       copy: (data: any) => data?.to,
    //       tooltip: (data: any) => (
    //         <Link to={`/address/${data?.to}`}>{data?.to}</Link>
    //       ),
    //       render: (data: any) => (
    //         <Link to={`/address/${data?.to}`}>{data?.to}</Link>
    //       ),
    //     },
    //     amount: {
    //       title: `${t("Amount")}`,
    //       param: "amount",
    //       tooltip: true,
    //       render: (data: any) => <span>{data?.amount}</span>,
    //     },
    //     value: {
    //       title: `${t("Value")}`,
    //       param: "value",
    //       tooltip: true,
    //       render: (data: any) => <span>${data?.value}</span>,
    //     },
    //     asset: {
    //       title: `${t("Asset")}`,
    //       param: "address",
    //       tooltip: true,
    //       render: (data: any) => (
    //         <p className="text-truncate">
    //           <Link to={`/token/`} className="d-flex align-items-center">
    //             <img
    //               src="https://assets.coingecko.com/coins/images/325/small/Tether.png?1696501661"
    //               width="20px"
    //               className="me-1"
    //             />
    //             {data?.name} ({data?.symbol})
    //           </Link>
    //         </p>
    //       ),
    //     },
    //     txnFee: {
    //       title: `${t("Txn Fee")}`,
    //       param: "txnfee",
    //       tooltip: true,
    //       render: (data: any) => <span>{data?.txnfee}</span>,
    //     },
    //   };

    const header = useMemo(() => {
        return [
            {
                title: (
                    <BTooltip text={t("See preview of the transaction details.")}>
                        <i className="bi bi-question-circle me-2 ps-2"></i>
                    </BTooltip>
                ),
                param: "header",
                render: (data: any) => (
                    <div>
                        <BTooltip
                            light={true}
                            text={
                                <React.Fragment>
                                    <div className="bs-transaction-additional-info p-2">
                                        <h2 className="bs-card-header-title">
                                            {t("Additional Info")}
                                        </h2>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">{t("Status")}:</p>
                                            <p className="bs-transaction-content">
                                                <TxStatus status={data?.status}/>{" "}
                                                {getPlural("block", data?.confirmations)}{" "}
                                                {t("confirmation")}
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">
                                                {t("Transaction Action")}:
                                            </p>
                                            <p className="bs-transaction-content">
                                                {t("Transfer")}:{" "}
                                                {formatUnit(toXDCNumber(data?.value, 18, 0))}
                                                <br/>
                                                {t("To")}:{" "}
                                                <Link to={`/address/${get0x(data?.to?.hash)}`}>
                                                    <AddressName data={data?.to}/>
                                                </Link>
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">
                                                {t("Transaction Fee")}:
                                            </p>
                                            <p className="bs-transaction-content">
                                                {formatUnit(
                                                    toXDCNumber(data?.gas_price * data?.gas_used, 18)
                                                )}
                                                <BPrice
                                                    value={data?.fee?.value}
                                                    coingeckoID={process.env.REACT_APP_COINGECKO_ID}
                                                    timestamp={getTimeFromNow(data?.timestamp)}
                                                    XDCUSD="XDCUSD"
                                                    decimal={0}
                                                    type=""
                                                />
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">Gas Info:</p>
                                            <p className="bs-transaction-content">
                                                {formatNumber(data?.gas_limit || 0)} |{" "}
                                                {formatNumber(data?.gas_used || 0)}
                                                <br/>
                                                {formatUnit(toXDCNumber(data?.gas_price, 18))} (
                                                {toGwei(data?.gas_price)} Gwei)
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <p className="bs-transaction-header">{t("Nonce")}:</p>
                                            <p className="bs-transaction-content">
                                                {data?.nonce} ({t("in the position")} {data?.position})
                                            </p>
                                        </div>
                                        <hr/>
                                        <div className="py-2">
                                            <Link to={`/tx/${get0x(data?.hash)}`}>
                                                {t("See more details")} &nbsp;
                                                <i className="bi bi-arrow-up-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            options={{
                                position: ["right center", "right top", "right bottom"],
                                on: ["click"],
                            }}
                        >
                           <button className="btn btn-sm btn-white fs-70x content-center btn-hov" style={{width: "1.75rem",height: "1.75rem"}}> <i className="bi bi-eye"></i></button>   
                        </BTooltip>
                    </div>
                ),
            },
            {
                title: `${t("Tx Hash")}`,
                param: "tx_hash",
                tooltip: (data: any) => (
                    <Link to={`/tx/${get0x(data?.tx_hash)}`}>
                        {getXDC(data?.tx_hash)}
                    </Link>
                ),
                render: (data: any) => (
                    <>
            {data?.status === "error" || data?.status === 0 ? (
                <strong>
                    <i
                        className="bi bi-exclamation-circle text-danger bold"
                        style={{fontWeight: "bold"}}
                    ></i>
                </strong>
            ) : null}{" "}
                        <Link to={`/tx/${get0x(data?.tx_hash)}`}>
                 {truncate(get0x(data?.tx_hash))} 
              &nbsp;<BCopy value={get0x(data?.tx_hash)} tooltip={`${t("Transaction Hash")}`}/>
            </Link>
          </>
                ),
            },
            {
                title: `${t("Method")}`,
                param: "method",
                tooltip: true,
                render: (data: any) => <MethodName data={data}/>,
            },
            {
                title: `${t("Age")}`,
                param: "timestamp",
                tooltip: true,
                render: (data: any) => getTimeLang(data?.timestamp, t),
            },
            {
                title: `${t("From")}`,
                param: "from",
                headerStyle: {minWidth: "130px"},
                copy: (data: any) => getXDC(data?.from?.hash),
                tooltip: (data: any) => (
                    <Link to={`/address/${get0x(data?.from?.hash)}`}>
                        {getXDC(data?.from?.hash)}
                    </Link>
                ),
                render: (data: any) => (
                    <Link to={`/address/${get0x(data?.from?.hash)}`}>
                        <AddressName data={data?.from}/>
                    </Link>
                ),
            },
            {
                title: "",
                param: "from",
                render: (data: any) => (
                    <span
                        className="bg-success bg-opacity-10 border border-success border-opacity-25 text-success fs-70x content-center rounded-pill mx-auto"
                        style={{width: "1.5rem", height: "1.5rem"}}
                    >
                        <i className="bi bi-arrow-right"></i>
                    </span>
                ),
            },
            {
                title: `${t("To")}`,
                param: "to",
                headerStyle: {minWidth: "130px"},
                copy: (data: any) => getXDC(data?.to?.hash),
                tooltip: (data: any) => (
                    <Link to={`/address/${get0x(data?.to?.hash)}`}>
                        {getXDC(data?.to?.hash)}
                    </Link>
                ),
                render: (data: any) => (
                    <Link to={`/address/${get0x(data?.to?.hash)}`}>
                        <AddressName data={data?.to}/>
                    </Link>
                ),
            },
            {
                title: `${t("Value")}`,
                param: "value",
                headerStyle: {minWidth: "130px"},
                tooltip: true,
                render: (data: any) => (
                    <React.Fragment>
                        {toXDCNumberWithoutFormat(
                            data?.total?.value,
                            data?.total?.decimals
                        ) * Number(data?.fiat_value ?? 1)}
                    </React.Fragment>
                ),
            },
            {
                title: `${t("Token")}`,
                param: "token",
                tooltip: true,
                render: (data: any) => (
                    <p className="text-truncate">
                        <Link
                            to={`/token/${get0x(data?.token?.address)}`}
                            className="d-flex align-items-center"
                        >
                            <TokenImage image={data?.token?.icon_url} symbol={data?.token?.symbol} params={{width: 20, className: "me-1"}}/>
                            {truncate(data?.token?.name || "")}({data?.token?.symbol})
                        </Link>
                    </p>
                ),
            },
        ];
    }, []);

    const toggleFullScreen = () => {
        const element = document.documentElement;

        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if ((element as any).webkitRequestFullscreen) {
            (element as any).webkitRequestFullscreen();
        }
    };

    const handleColumnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        setColumnSelectedOptions((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handlePreventClose = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Handle the form submission logic here
        console.log("Contract Address:", contractAddress);
        console.log("Topic 0:", topic0);
    };

    // const toggleAdditionalFilters = () => {
    //     setShowAdditionalFilters(!showAdditionalFilters);
    // };

    return (
        <div className="py-3">
            <div className="container px-4">
                <div className="bs-page-title bs-advance-filter-title">
                    <div className="advanced-filter">
                        Advanced Filter{" "}
                        <span
                            className="bs-badge h-small h-gray"
                            style={{marginLeft: "10px"}}
                        >
                            Beta
                        </span>
                    </div>
                    <div>
                        <div className="d-flex gap-3">
                            <a
                                className="btn btn-sm btn-light filter-btn d-block d-md-inline-block mb-2 mb-md-0 align-self-center"
                                href="#"
                            >
                                <i className="bi bi-trophy"></i>
                                <span> Leaderboard</span>
                            </a>
                            <span style={{fontWeight: "300"}}>|</span>
                            <a
                                className="btn btn-sm btn-light filter-btn d-md-inline-block mb-2 mb-md-0 align-self-center"
                                href="#"
                            >
                                <i className="bi bi-book"></i>
                            </a>
                            <button
                                type="button"
                                className="btn btn-sm btn-primary align-self-center"
                                data-bs-toggle="modal"
                                data-bs-target="#advanceFilterModal"
                            >
                                <i className="bi bi-floppy"></i>
                                <span> Save Filter</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 bs-advance-filter-display-chart">
                        <div className="text-size-1 fs-6">
                            <i className="bi bi-funnel"></i>
                            <span> Filtered By</span>
                        </div>

                        <div className="d-flex gap-2">
                            <label
                                className="form-check-label text-size-1"
                                htmlFor="displaychart"
                            >
                                Display Chart:
                            </label>
                            <span className="form-check form-switch bs-custom-form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="displaychart"
                                    checked={displayChart}
                                    onChange={handleCheckboxChange}
                                />
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="d-flex flex-wrap align-items-center gap-1 py-2">
                        <div
                            className="d-flex align-items-center bg-secondary bg-opacity-10 border border-dark dark:border-white border-opacity-15 fs-sm text-dark text-nowrap rounded-pill gap-1 py-0.5 px-2 py-1">
                            <span className="text-muted">Type:</span>
                            <BTooltip text="All">
                                <span className="d-flex fw-medium text-size-1 text-nowrap">
                                    All
                                </span>
                            </BTooltip>
                        </div>
                        <div
                            className="d-flex align-items-center bg-secondary bg-opacity-10 border border-dark dark:border-white border-opacity-15 fs-sm text-dark text-nowrap rounded-pill gap-1 py-0.5 px-2 py-1">
                            <span className="text-muted">Age:</span>
                            <BTooltip text="7D">
                                <span className="d-flex fw-medium text-size-1 text-nowrap">
                                    7D
                                </span>
                            </BTooltip>
                        </div>
                    </div>
                </div>

                {displayChart && <AdvancedFilterChart/>}

                {/* <ListingLayout2
          title=""
          listingHeader={
            <div className="d-flex align-items-center justify-content-between flex-1">
              <div className="font-weight-medium text-secondary">
                A total of {totalRecords} transactions found <br />
                (Showing the last 50,000 records only)
              </div>
              <div className="d-flex gap-2">
                <a
                  onClick={downloadCSV}
                  className="btn btn-sm btn-light filter-btn"
                >
                  <i className="bi bi-file-earmark-arrow-down"></i>
                  <span> Download Data</span>
                </a>
                <div className="dropdown">
                  <button
                    className="btn btn-sm btn-light filter-btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Columns
                  </button>
                  <ul
                    className="dropdown-menu p-3"
                    aria-labelledby="dropdownMenuButton"
                    style={{ minWidth: "230px" }}
                  >
                    {Object.keys(columnSelectedOptions).map((key) => (
                      <li
                        key={key}
                        className="custom-checkbox-align"
                        onClick={handlePreventClose}
                      >
                        <label htmlFor={key} style={{ fontWeight: "normal" }}>
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </label>
                        <span className="form-check form-switch bs-custom-form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={key}
                            name={key}
                            checked={
                              columnSelectedOptions[
                                key as keyof ColumnSelectedOptions
                              ]
                            }
                            onChange={handleColumnChange}
                          />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <a
                  className="btn btn-sm btn-light filter-btn"
                  onClick={toggleFullScreen}
                >
                  <i className="bi bi-arrows-fullscreen"></i>
                </a>
                <a
                  className="btn btn-sm btn-light filter-btn"
                  data-bs-toggle="offcanvas"
                  href="#additionalFilters"
                  role="button"
                  aria-controls="additionalFilters"
                >
                  <i className="bi bi-filter"></i>
                  <span> Additional Filters</span>
                </a>
              </div>
            </div>
          }
          pagination={pagination}
          totalRecords={totalRecords}
          onPagination={handle.pagination}
          loading={loading}
          list={advancedFilter}
          header={header}
        /> */}
                <div className="row">
                    <div className="col-lg-12">
                        <BTable2
                            title={t("Token Transactions")}
                            header={header}
                            list={transactionList}
                            loading={loading}
                            pagination={pagination}
                            totalRecords={totalRecords}
                            onPagination={handle.pagination}
                        />
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="advanceFilterModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="advanceFilterModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="advanceFilterModalLabel">
                                Advanced Filter
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            To use this feature, please{" "}
                            <a href="#" style={{textDecoration: "none"}}>
                                login
                            </a>{" "}
                            to your {process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} account and return to this page.
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="offcanvas offcanvas-start"
                tabIndex={-1}
                id="additionalFilters"
                aria-labelledby="additionalFiltersLabel"
            >
                <div className="offcanvas-header bs-offcanvas-title">
                    <h5
                        className="offcanvas-title bs-offcanvas-title-font"
                        id="additionalFiltersLabel"
                    >
                        Additional Filters
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <h3 className="mb-4" style={{fontSize: "0.9375rem"}}>
                        Event Log (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://info.etherscan.com/what-is-event-logs/"
                        >
                            Learn more
                        </a>
                        )
                    </h3>
                    <input
                        type="hidden"
                        name="ctl00$ContentPlaceHolder1$hdnIsEventLogFiltersValid"
                        id="hdnIsEventLogFiltersValid"
                        value="true"
                    />
                    <div className="card bg-light shadow-none p-4 mb-5">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label
                                    className="form-label"
                                    htmlFor="inputEventLogContractAddress"
                                >
                                    Address
                                </label>
                                <input
                                    name="ctl00$ContentPlaceHolder1$inputEventLogContractAddress"
                                    type="text"
                                    id="ContentPlaceHolder1_inputEventLogContractAddress"
                                    className="form-control form-control-sm"
                                    placeholder="0x..."
                                    value={contractAddress}
                                    onChange={(e) => setContractAddress(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="inputTopic0">
                                    Topic 0
                                </label>
                                <input
                                    name="ctl00$ContentPlaceHolder1$inputTopic0"
                                    type="text"
                                    id="ContentPlaceHolder1_inputTopic0"
                                    className="form-control form-control-sm"
                                    placeholder="0x..."
                                    value={topic0}
                                    onChange={(e) => setTopic0(e.target.value)}
                                />
                            </div>
                            <div className="mb-0">
                                <button
                                    type="submit"
                                    name="ctl00$ContentPlaceHolder1$btnEventLogFilter"
                                    id="ContentPlaceHolder1_btnEventLogFilter"
                                    className="btn btn-sm btn-primary"
                                >
                                    Apply
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedFilter;
