import React, {useEffect, useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import moment from "moment";

interface ChartDataItem {
    date: string;
    tx_count: number;
}

const ChartComponent = () => {
    const [chartData, setChartData] = useState<{ categories: string[]; data: number[] }>({
        categories: [],
        data: [],
    });
    const [loading, setLoading] = useState(true);

    const options: Highcharts.Options = {
        chart: {
            type: "column",
            height: 140,
        },
        credits: {enabled: false},
        exporting: {
            enabled: false,
        },
        title: {
            text: "",
        },
        xAxis: {
            categories: chartData.categories,
            title: { text: null },
        },
        yAxis: {
            min: 0,
            title: {
                text: "",
                align: "high",
            },
            labels: {
                overflow: "justify",
            },
        },
        legend: { enabled: false },
        tooltip: {
            useHTML: true,
            shadow: true,
            split: false,
            outside: true,
            shared: true,
            formatter: function (this: any) {
                const dateString = moment(this.x, "DD. MMM").format("ddd D, MMM YYYY");
                let tooltipText = `<div>${dateString}</div><hr style="margin-top: 5px; margin-bottom: 5px;">`;
                tooltipText += `<div style="font-size: 13px;">Txns Count:<span style="font-weight: bold;"> ${this.y}</span></div>`;
                return tooltipText;
            },
        },
        plotOptions: {
            column: {
                cursor: "pointer",
                dataLabels: { enabled: false },
            },
        },
        series: [
            {
                type: "column",
                name: "Txns Count",
                data: chartData.data,
            },
        ],
    };

    const getChartColumn = async () => {
        try {
            setLoading(true);
            let fromDate = moment().subtract(8, "days").format("YYYY-MM-DD");
            let toDate = moment().format("YYYY-MM-DD");
            const response = await axios.get(`${process.env.REACT_APP_CHART_URL}/stats/charts/transactions?from=${fromDate}&to=${toDate}`);
            const data = response?.data?.chart_data
                .map((item: any) => ({
                    ...item,
                    date: moment(item?.date)
                }))
                .sort((a: { date: moment.Moment }, b: { date: moment.Moment }) => a.date.diff(b.date));

            // Get the last 8 days
            const last8DaysData = data.slice(-8);
            const categories = last8DaysData.map((item: { date: moment.Moment }) => item?.date?.format("DD. MMM"));
            const txData = last8DaysData.map((item: { tx_count: number }) => item?.tx_count);
            setChartData({
                categories,
                data: txData,
            });
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getChartColumn();
    }, []);

    return (
        <div style={{ marginTop: "20px" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default ChartComponent;
