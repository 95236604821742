import React from 'react';
import useWallet from '../../../../common/hooks/useWallet';
import AddressEntity from '../support-files/addressEntity';

const WalletButton: React.FC = () => {
  const {
    isWalletConnected,
    addressHash,
    connect,
    disconnect,
    isModalOpening,
  } = useWallet();

  const handleConnectClick = async () => {
    if (isWalletConnected) {
      disconnect();
    } else {
      connect();
    }
  };

  return (
    <button
      type="button"
      className="btn btn-sm nav-item bs-sign-btn"
      onClick={handleConnectClick}
      disabled={isModalOpening}
    >
      {isWalletConnected ? (
        addressHash && (
            <AddressEntity
              address={{ hash: addressHash }} 
              truncation="constant"
              fontWeight={500}
            />
        )
      ) : (<React.Fragment>
      <i className="bi bi-person-circle" style={{padding: "5px"}}></i> 
      Sign In
      </React.Fragment>
      )}
    </button>
  );
};

export default WalletButton;
