import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import store from './store';
import {Provider} from 'react-redux';
import "./i18n/i18n";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'reactjs-popup/dist/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import {createWeb3Modal} from '@web3modal/wagmi/react';
import {defaultWagmiConfig} from '@web3modal/wagmi/react/config';
import {WagmiProvider} from 'wagmi';
import {arbitrum, Chain, mainnet} from 'wagmi/chains';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const setThemeColor = () => {
  const bsTheme: any = {
    "--color-theme": `${process.env.REACT_APP_BS_THEME}` || "#081d35", //"#081d35",
    "--color-primary": `${process.env.REACT_APP_BS_PRIMARY}` || "#34a1ff", //"#34a1ff",
  }

  const root = window.document.documentElement;
  if (root) {
    for (let label in bsTheme) {
      const value = bsTheme[label];
      // @ts-ignore
      root?.style.setProperty(label, value);
    }
  }
}
// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_MULTI_WALLET_PROJECT_ID || 'fad5926728c846370814249760f70779';

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};

const chains: readonly [Chain, ...Chain[]] = [mainnet, arbitrum];

const config = defaultWagmiConfig({
  projectId,
  chains,
  metadata,
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  themeVariables: {
    '--w3m-color-mix': `${process.env.REACT_APP_BS_THEME}` || "#081d35",
    '--w3m-color-mix-strength': 40
  }
});

setThemeColor()
const root = ReactDOM.createRoot(
  document.getElementById('bs-root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <WagmiProvider config={config}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </WagmiProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
