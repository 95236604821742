import React from 'react';
import BIdenticons from '../../../../components/b-identicon';

interface AddressEntityProps {
  address: { hash: string };
  truncation?: 'constant' | 'dynamic';
  fontWeight: number;
}

const truncateAddress = (address: string, truncation?: 'constant' | 'dynamic') => {
  if (truncation === 'constant') {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  } else if (truncation === 'dynamic') {
    return `${address.slice(0, 10)}...${address.slice(-10)}`;
  }
  return address; 
};

const AddressEntity: React.FC<AddressEntityProps> = ({ address, truncation, fontWeight }) => {
  const truncatedAddress = truncateAddress(address.hash, truncation);

  return (
    <div className="d-flex align-items-center">
      <BIdenticons
        seed={address.hash}
        size={7}
        scale={3}
        color="#3498db"
        bgColor="#d35400"
      />
      <span className="address-text ms-2" style={{ fontWeight }}>{truncatedAddress}</span>
    </div>
  );
};

export default AddressEntity;
