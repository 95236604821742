import React, {useState} from 'react';
import useWallet from '../../../common/hooks/useWallet';
import MSK from '../../../images/MetaMask_Fox.svg';

const MetaMaskButton: React.FC = () => {
  const {
    isWalletConnected,
    addressHash,
    connect,
    disconnect,
    isModalOpening,
  } = useWallet();

  const [isRequestPending, setIsRequestPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleConnectClick = async () => {
    if (isWalletConnected) {
      disconnect();
    } else {
      setIsRequestPending(true);
      setErrorMessage(null);
      const timeout = setTimeout(() => {
        setErrorMessage('Please complete the MetaMask connection.');
        setIsRequestPending(false);
      }, 20000); // 20 seconds timeout

      try {
        await connect();
        clearTimeout(timeout);
        setIsRequestPending(false);
      } catch (error: any) {
        clearTimeout(timeout);
        if (error.code === -32002) {
          setErrorMessage('MetaMask request already pending. Please wait.');
        } else if (error.code === 4001) {
          setErrorMessage('User rejected the connection request.');
        } else if (error.message.includes('MetaMask is locked')) {
          setErrorMessage('MetaMask is locked. Please unlock MetaMask and try again.');
        } else {
          setErrorMessage('An unknown error occurred. Please try again.');
        }
        setIsRequestPending(false);
      }
    }
  };

  return (
    <div className="mb-3 mt-2">
      <button
        type="button"
        className="btn btn-sm bs-btn-cust"
        onClick={handleConnectClick}
        disabled={isModalOpening || isRequestPending}
      >
        <span style={{ marginRight: '5px' }}>
          <img src={MSK} alt="MetaMask Icon" height={16} width={16} style={{ marginBottom: '2px' }} />
        </span>
        {isWalletConnected ? `Disconnect: ${addressHash}` : 'Add to MetaMask'}
      </button>
      {errorMessage && (
        <div className="mt-2 text-danger">
          {errorMessage}
        </div>
      )}
      {isRequestPending && (
        <div className="mt-2 text-warning">
          Please complete the MetaMask connection.
        </div>
      )}
    </div>
  );
};

export default MetaMaskButton;