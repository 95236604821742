import React, {FC, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useDarkMode, useLocalStorage} from "usehooks-ts";
import {useDispatch, useSelector} from "react-redux";
import {common} from "../../../store/slicer/common";
import {dashboardService} from "../../../store/actions";
import HeaderMenu from "./header-menu";
import moment from "moment";
import {getFiat} from "../../../common/utils/function";
import {useTranslation} from "react-i18next";
import SearchBar from "../../dashboard/bs-dashboard/common/search-bar";
import TokenImage from "../../common/token-image";
import BAvatar from "../../common/b-avatar";

const Header: FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {isDarkMode, toggle} = useDarkMode();
    const [value, setValue] = useLocalStorage(
        "isDarkMode",
        isDarkMode ? "dark" : "light"
    );
    const prefix = useSelector((e: Object) => (e as any)?.common?.prefix);
    const usdPrice = useSelector((e: Object) => (e as any)?.common?.usdPrice);
    const statsData = useSelector((e: Object) => (e as any)?.common?.stats);
    const _config = useSelector((e: Object) => (e as any)?.common?.config);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isSmallPopoverOpen, setSmallPopoverOpen] = useState(false);
    const {t, i18n} = useTranslation();

    const networkList = _config?.explorer || {};
    const netExist = Object.keys(networkList).length === 0;

    const handleClick = (url: string) => {
        if (url) {
            window.location.href = url;
        }
    };

    const handleMouseEnter = () => {
        setIsPopoverOpen(true);
    };

    const handleMouseLeave = () => {
        setIsPopoverOpen(false);
    };
    const handleMouseEnterNew = () => {
        setSmallPopoverOpen(true);
    };

    const handleMouseLeaveNew = () => {
        setSmallPopoverOpen(false);
    };

    const themeList = [
        {
            label: t("Light"),
            icon: "bi bi-brightness-high",
            value: "light",
        },
        {
            label: t("Dim"),
            icon: "bi bi-moon-stars",
            value: "dim",
        },
        {
            label: t("Dark"),
            icon: "bi bi-moon",
            value: "dark",
        },
    ];

    const handle = {
        theme: (isDark: string) => {
            setValue(isDark);
        },
        togglePrefix: (e: any) => {
            dispatch(common.actions.actionPrefix());
        },
    };
    const [stats, setStats] = useState<any>(null);

    const getPrice = async () => {
        try {
            // axios.get(`https://xdc.blocksscan.io/api/setting/usd`).then((res) => {
            //   const data = {
            //     usd: Number(res?.data?.price ?? "0"),
            //     usd_24h_change: Number(res?.data?.percent_change_24h ?? "0"),
            //   };
            //   setPrice(data);
            //   dispatch(common.actions.actionUSDPrice(data));
            // });
            dashboardService
                .stats()
                .then((response) => {
                    setStats(response);
                    dispatch(common.actions.actionStats(response));
                })
                .catch((e) => "");
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getPrice();
    }, []);

    return (
        <React.Fragment>
            <div className="bs-master-header sticky-top">
                <div className="container d-flex align-items-center justify-content-between py-2">
                    <div
                        id="ethPrice"
                        className="d-flex align-items-sm-center gap-4 fs-sm ethPrice"
                    >
                        <div className="text-muted">
                            <span className="text-muted">
                                {process.env.REACT_APP_BASE_UNIT === "WYZ"? `${process.env.REACT_APP_BASE_UNIT} TBL` :  process.env.REACT_APP_BASE_UNIT } {t("Price")}:
                            </span>
                            &nbsp;
                            <Link to="/chart/market">{getFiat(stats?.coin_price, 5)} </Link>
                            &nbsp;
                             <OverlayTrigger
                            show={isSmallPopoverOpen}
                            overlay={
                                <Popover id="popover-basic">
                                   <div className="popover-text p-1">
                                        {t("Price changes in the last 24 hours")}
                                    </div>
                                </Popover>
                            }
                            placement="right"
                        >
                            <span
                                className={`small ${
                                    stats?.coin_price_change_percentage >= 0
                                        ? "text-success"
                                        : "text-danger"
                                }`}
                                onMouseEnter={handleMouseEnterNew}
                                onMouseLeave={handleMouseLeaveNew}
                            >
                                ({stats?.coin_price_change_percentage?.toFixed(2) ?? 0.00}%)
                            </span>
                        </OverlayTrigger>
                        </div>
                        <div className="text-muted">
                            <span className="text-muted">
                                <i className="fa fa-solid fa-gas-pump"></i> {t("Gas")}:
                            </span>
                            &nbsp;
                            <OverlayTrigger
                                show={isPopoverOpen}
                                placement="bottom"
                                overlay={
                                    <Popover
                                        className="gas-popover"
                                        id="popover-basic"
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {/* <Popover.Header as="h3">Popover Title</Popover.Header> */}
                                        <Popover.Body className="p-2">
                                            <div className="d-flex flex-column flex-gap-2">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <p className="text-secondary">{t("Last Updated")}</p>
                                                    <p className="text-size-1">
                                                        {moment(statsData?.gas_price_updated_at).format(
                                                            "MMM DD, HH:mm:ss"
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="mt-2">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p className="text-secondary">{t("Fast")}</p>
                                                        <p className="text-size-1">
                                                            {statsData?.gas_prices?.fast.price ?? 0} Gwei
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p className="text-secondary">{t("Normal")}</p>
                                                        <p className="text-size-1">
                                                            {statsData?.gas_prices?.average.price ?? 0} Gwei
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p className="text-secondary">{t("Slow")}</p>
                                                        <p className="text-size-1">
                                                            {statsData?.gas_prices?.slow.price ?? 0} Gwei
                                                        </p>
                                                    </div>
                                                </div>
                                                <Link
                                                    data-theme="dark"
                                                    className="mt-2"
                                                    to="/gastracker"
                                                    onClick={() => setIsPopoverOpen(false)}
                                                >
                                                    {t("Gas tracker overview")}
                                                </Link>
                                            </div>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                {/* <span> */}
                                <a href="#" onMouseEnter={handleMouseEnter}>
                                    {/* {getFiat(
                                        toXDCNumber(stats?.gas_prices?.average * usdPrice?.usd, 2),
                                        2
                                    )} */}
                                    {statsData?.gas_prices?.average.price ?? 0} {"  "}
                                    Gwei
                                </a>
                                {/* </span> */}
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center gap-2">
                        {location.pathname !== '/' && <SearchBar/>}
                        {process.env.REACT_APP_PREFIX === "xdc" && (
                            <div>
                                <div className="bs-switch">
                                    <input
                                        type="radio"
                                        id="xdc"
                                        name="switchPlan"
                                        value="xdc"
                                        checked={prefix === (process.env.REACT_APP_PREFIX || "xdc")}
                                        onChange={handle.togglePrefix}
                                    />
                                    <input
                                        type="radio"
                                        id="0x"
                                        name="switchPlan"
                                        value="0x"
                                        checked={prefix !== (process.env.REACT_APP_PREFIX || "xdc")}
                                        onChange={handle.togglePrefix}
                                    />
                                    <label htmlFor="xdc">xdc</label>
                                    <label htmlFor="0x">0x</label>
                                    <div className="switch-wrapper">
                                        <div className="switch">
                                            <div>xdc</div>
                                            <div>0x</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="btn-group">
                            <button
                                className="btn btn-outline-primary dropdown-toggle theme-dropdown"
                                type="button"
                                id="defaultDropdown"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="false"
                            >
                                {value == "dark" ? (
                                    <i className="bi bi-moon"></i>
                                ) : value == "dim" ? (
                                    <i className="bi bi-moon-stars"></i>
                                ) : (
                                    <i className="bi bi-brightness-high"></i>
                                )}
                            </button>
                            <ul
                                className="dropdown-menu theme-dropdown-menu"
                                aria-labelledby="defaultDropdown"
                            >
                                {themeList.map((ele, index) => (
                                    <li key={index}>
                                        <a
                                            className="dropdown-item"
                                            href="javascript:void(0)"
                                            onClick={() => handle.theme(ele?.value)}
                                        >
                                            <div>
                                                <i className={`${ele?.icon}`}></i>
                                                {ele?.label}
                                            </div>
                                            <span className={`theme-block bg-${ele?.label}`}></span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {!netExist ? (
                            <div className="btn-group">
                            <button
                                className="btn btn-outline-primary dropdown-toggle theme-dropdown"
                                type="button"
                                id="defaultDropdown"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="false"
                            >
                                <BAvatar className="mr-2" size={17} />
                            </button>
                            <ul className="dropdown-menu theme-dropdown-menu px-2 py-1" aria-labelledby="defaultDropdown">
                                {Object.entries(networkList).map(([networkType, networks]) => (
                                    <React.Fragment key={networkType}>
                                        <li className="dropdown-header"></li>
                                        {Object.entries(networks as Record<string, string>).map(([networkName, url], index) => (
                                            <li key={index}>
                                                <a
                                                    className="dropdown-item"
                                                    href="javascript:void(0)"
                                                    onClick={() => handleClick(url)}
                                                >
                                                    {networkName}
                                                </a>
                                            </li>
                                        ))}
                                        <li><hr className="dropdown-divider" /></li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <header className="bs-header">
                <nav className="navbar navbar-expand-lg">
                    <div className="container d-flex justify-content-between position-relative">
                    <Link className="navbar-brand" to="/">
                        {process.env.REACT_APP_BASE_UNIT === "WYZ" ? (
                            <>
                            <img
                                className="bs-logo bs-logo-dark"
                                src={`${process.env.REACT_APP_BASE_URL}/eaas/img/${process.env.REACT_APP_BASE_UNIT}-dark-logo.svg`}
                            />
                            <img
                                className="bs-logo bs-logo-light"
                                src={`${process.env.REACT_APP_BASE_URL}/eaas/img/${process.env.REACT_APP_BASE_UNIT}-light-logo.svg`}
                            />
                            </>
                        ) : (
                            <span className="text-muted" style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', lineHeight: '35px' }}>
                            <TokenImage 
                                image={stats?.coin_image} 
                                symbol={process.env.REACT_APP_BASE_UNIT}
                                params={{ width: 34, height: 34, className: "bs-icon-avatar mr-2" }} 
                            /> &nbsp;
                            {process.env.REACT_APP_BASE_UNIT}Scan
                            </span>
                        )}
                        </Link>


                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo01"
                            aria-controls="navbarTogglerDemo01"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"/>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <HeaderMenu/>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </React.Fragment>
    );
};

export default Header;