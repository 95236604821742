import React from 'react';
import {useWeb3Modal, useWeb3ModalState} from '@web3modal/wagmi/react';
import {useAccount, useDisconnect} from 'wagmi';

export default function useWallet() {
  const { open } = useWeb3Modal();
  const { open: isOpen } = useWeb3ModalState();
  const { disconnect } = useDisconnect();
  const [isModalOpening, setIsModalOpening] = React.useState(false);
  const [isClientLoaded, setIsClientLoaded] = React.useState(false);
  const isConnectionStarted = React.useRef(false);

  React.useEffect(() => {
    setIsClientLoaded(true);
  }, []);

  const handleConnect = React.useCallback(async () => {
    setIsModalOpening(true);
    await open();
    setIsModalOpening(false);
  }, [open]);

  const handleAccountConnected = React.useCallback(({ isReconnected }: { isReconnected: boolean }) => {
    // No implementation needed here for now
    return () => {};
  }, []);

  const handleDisconnect = React.useCallback(() => {
    disconnect();
  }, [disconnect]);

  const { address, isDisconnected } = useAccount();

  const isWalletConnected = isClientLoaded && !isDisconnected && address !== undefined;

  return {
    isWalletConnected,
    addressHash: address || '',
    connect: handleConnect,
    disconnect: handleDisconnect,
    isModalOpening,
    isModalOpen: isOpen,
  };
}