import React, {FC} from "react";
import Blockies from "react-blockies";

interface BIdenticonsProps {
  seed: string;
  size?: number;
  scale?: number;
  color?: string;
  bgColor?: string;
  spotColor?: string;
  className?: string;
}

const BIdenticons: FC<BIdenticonsProps> = ({
  seed,
  size = 10,
  scale = 3,
  color = "#dfe",
  bgColor = "#ffe",
  spotColor = "#abc",
  className = "bs-identicon",
}) => {
  return (
    <Blockies
      seed={seed}
      size={size}
      scale={scale}
      color={color}
      bgColor={bgColor}
      spotColor={spotColor}
      className={className}
    />
  );
};

export default BIdenticons;
