import React, {FC, useEffect, useRef, useState} from "react";
import {onImgError, onNFTError} from "../../../common/utils/function";
//@ts-ignore
import ColorThief from 'colorthief/dist/color-thief.mjs';

interface TokenImageProps {
    image: any,
    symbol?: any,
    nft?: boolean,
    params?: any,
    bg?: any,
}

const TokenImage: FC<TokenImageProps> = (props) => {
    const url = props?.nft ? "" : `${process.env.REACT_APP_BASE_URL}/tokens/img/${props?.symbol?.toLowerCase()}.png`

    const imageRef = useRef<any>(null);
    useEffect(() => {
        const imgElement = imageRef.current;
        const extractColor = async () => {
            if (props?.bg && imgElement) {
                await imgElement.decode();
                const colorThief = new ColorThief();
                const color = colorThief.getColor(imgElement);
                const body = document.getElementsByClassName('bs-body')[0] as HTMLElement
                body.style.backgroundImage = `linear-gradient(rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.1) 0%, rgba(246, 248, 250, 0) 16rem)`
            }
            ;

        }
        if (imgElement && imgElement.complete) {
            extractColor();
        } else {
            imgElement && imgElement.addEventListener('load', extractColor);
        }


        return () => {
            const body = document.getElementsByClassName('bs-body')[0] as HTMLElement
            body.style.backgroundImage = ""
            if (props?.bg && imgElement) imgElement.removeEventListener('load', extractColor);
        };
    }, [props?.image ?? url]);

    return (<img ref={imageRef} src={props?.image ?? url} onError={props?.nft ? onNFTError : onImgError} {...props?.params} alt="Token" crossOrigin="anonymous"/>);
};

export default TokenImage;
