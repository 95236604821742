import React, {FC, useState} from "react";
// @ts-ignore
import Highcharts from "highcharts";
// @ts-ignore
import HighchartsReact from "highcharts-react-official";
import Highcharts3D from "highcharts/highcharts-3d";
import {useNavigate} from "react-router-dom";
import HighchartsExporting from "highcharts/modules/exporting";
import TransactionAccountList from "../common/dex-transaction-list";

HighchartsExporting(Highcharts);
Highcharts3D(Highcharts);

const AI_TYPE = [
    {name: "All Time", value: "all"},
    {name: "Last 7 Days", value: "7days"},
    {name: "Last 30 Days", value: "30days"},
];

const DexChart: FC = (props: any) => {
    let navigate = useNavigate();
    const [latestTxn, setLatestTxn] = useState<any>(null);
    const [selectedAI, setSelectedAI] = useState(AI_TYPE[0]);

    const [options, setOptions] = useState({
        chart: {
            type: "pie",
            options3d: {
                enabled: true,
                alpha: 45,
                beta: 0,
            },
        },
        exporting: {
            enabled: true,
        },
        credits: {enabled: false},
        title: {
            text: "DEX Activity",
            align: "center",
        },
        subtitle: {
            text: "In the last 7 days",
            align: "center",
        },
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45,
            },
        },
        series: [
            {
                name: "Medals",
                data: [
                    ["Norway", 16],
                    ["Germany", 12],
                    ["USA", 8],
                    ["Sweden", 8],
                    ["Netherlands", 8],
                    ["ROC", 6],
                    ["Austria", 7],
                    ["Canada", 4],
                    ["Japan", 3],
                ],
            },
        ],
    });
    return (
        <React.Fragment>
            <div className="py-3">
                <div className="container">
                    <div className="row">
                        <div className="d-flex flex-wrap justify-content-between align-items-center border-bottom gap-3 py-3">
                            {/* <div >{process.env.REACT_APP_BASE_UNIT} Daily Price (USD) Chart</div> */}
                            <div className="d-flex flex-column gap-2">
                                <a className="small" href="javaScript:void(0)" onClick={() => navigate("/charts")}>
                                    <i className="fa fa-arrow-left"></i> Charts & Stats
                                </a>
                                <h1 className="page-title h5 mb-0">DEX Activity</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-4 pb-5">
                    <div className="card mb-4">
                        <div className="card-body">
                            <span>
                                A breakdown of activity on all decentralized exchanges tracked by {process.env.REACT_APP_BASE_UNIT}. Includes the transaction count and percentage of total.{" "}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-start mb-2">
                        <i className="fa fa-filter me-2"></i>
                        <span className="me-2 text-size-3">Range</span>
                        <button
                            className="btn btn-outline-secondary dropdown-toggle theme-dropdown me-2 p-1"
                            type="button"
                            id="defaultDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="true"
                            aria-expanded="false"
                        >
                            <span className="text-size-3">{selectedAI.name}</span>
                        </button>
                        <ul className="dropdown-menu theme-dropdown-menu" aria-labelledby="defaultDropdown">
                            {AI_TYPE.map((ele, index) => (
                                <li key={index}>
                                    <a
                                        className="dropdown-item"
                                        href="javascript:void(0)"
                                        onClick={() => {
                                            setSelectedAI(ele);
                                        }}
                                    >
                                        <div>
                                            <span className="text-size-3"> {ele?.name}</span>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-5 mb-lg-0">
                            <div className="bs-card p-3 mb-4">
                                <div className="card-body">
                                    <HighchartsReact highcharts={Highcharts} options={options}/>
                                    <TransactionAccountList count={5} transactionData={(data: any) => setLatestTxn(data)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DexChart;
