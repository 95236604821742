import React, {FC} from "react";
import Popup from 'reactjs-popup';

interface BTooltipData {
  children: any;
  text: any,
  options?: any,
  className?: string,
  light?: boolean,
}
const POSITION_TYPES = [
  'top center',
  'bottom center',
  'right center',
  'left center',
  'top left',
  'top right',
  'right top',
  'right bottom',
  'bottom left',
  'bottom right',
  'left top',
  'left bottom',
  'center center',
];

const BTooltip: FC<BTooltipData> = (props) => {
  return  <Popup trigger={<span>{props?.children}</span>}  position={POSITION_TYPES} on={["hover"]} {...props?.options} className={`${props?.light ? 'bs-tooltip-light' : 'bs-tooltip'} ${props?.className}`} lockScroll={true} KeepTooltipInside={true}>
    <div className={props?.light ? 'bs-tooltip-light-text' : 'bs-tooltip-text'}>{props?.text}</div>
  </Popup>;
};

export default BTooltip;
